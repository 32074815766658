.tabs-component {
  width: 100%;
  height: 100%;
  .tabs-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    width: 100%;

    button {
      width: 50%;
      border: 0;
      background: $grey-light;
      padding: 20px 0;
      cursor: pointer;
      &.active {
        background: $blue-light;
        padding-top: 17px;
        border-top: 3px solid $xiaomi;
        cursor: unset;
      }
      &:focus {
        outline: none;
      }
      &:last-child {
        display: none;
      }
    }
  }
  .single-tab {
    padding: 40px 30px 40px 30px;
  }
}

.tab-button {
  background-color: $black;
  border: none;
  color: $white;
  border-radius: 5px;
  height: 35px;

  &__container {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 5px;
  }

  &__text {
    font-size: 14px;
    &--grey {
      color: $text-grey2;
    }
  }

  div svg {
    width: 20px;
    height: 24px;
  }
}

.tab__title {
  font-size: 12px;
  font-weight: bold;
  color: $text-grey;
  text-transform: uppercase;
  margin: 45px 0 15px 0;
  &:first-child {
    margin: 0 0 15px 0;
  }
}

.tab__title--exhibitor {
  order: -2;
}

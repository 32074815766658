.person {
  display: flex;
  align-items: center;
  color: $black;
  gap: 10px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: bold;

    small {
      font-size: 12px;
      color: $text-grey;
    }
  }
}

.person-image {
  position: relative;

  img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }

  &--online::after,
  &--offline::after {
    content: '';
    width: 12px;
    height: 12px;
    bottom: 0;
    right: 3px;
    position: absolute;
    border-radius: 50%;
    border: 2px solid $white;
    background-color: $green;
  }

  &--offline::after {
    background-color: $red;
  }
}

.person-big {
  @media (max-width: $tabletSmall) {
    display: flex;
    flex-direction: column;
  }
  .person__wrapper {
    font-size: 20px;
    small {
      font-size: 16px;
    }
    @media (max-width: $tabletSmall) {
      text-align: center;
    }
  }
  .person-image {
    img {
      width: 120px;
      height: 120px;
    }
  }
}

.person-content {
  margin: 2em 0 0 0;
  p {
    font-size: 16px;
  }
}

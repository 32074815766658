.sidebar {
  background: $black;
  color: $text-grey;
  min-width: 308px;
  max-width: 308px;
  width: 308px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;

  &__content {
    height: 100%;
    width: 308px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: space-between;
    > div {
      width: 100%;
    }
    label {
      padding: 30px 0;
      height: 30px;
      width: 100%;
      border-bottom: 1px solid $border-grey;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    input {
      background: transparent;
      color: $white;
      border: 0;
      padding-left: 10px;
      &:focus {
        outline: none;
      }
    }
    section {
      padding: 20px;
      small {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        margin: 25px 0;
        display: block;
      }
      ul.sidebar__list {
        background: #fff;
        border-radius: 5px;
        box-shadow: 0px 3px 6px $shadow;
        padding-bottom: 22px;
        width: 100%;
        max-height: 300px;
        overflow-y: scroll;
        transition: height 0.3s;
        a {
          text-decoration: none;
          color: inherit;
        }
        li {
          margin-bottom: 14px;
          padding-left: 30px;
          transition: color 0.3s ease-in-out;
          cursor: unset;
          &.sidebar__list-buttons {
            // background: $orange;
            background: linear-gradient(to bottom, $xiaomi 50%, transparent 50%);
            border-radius: 5px 5px 0 0;
            padding: 10px 10px 0 10px;
            color: $white;
            span {
              padding: 10px;
              img {
                width: 14px;
              }
              &:first-of-type {
                // border-bottom: 1px solid $white-light;
                img {
                  border-radius: 50%;
                  background: $white-light;
                  padding: 5px;
                  padding-top: 6px;
                  padding-bottom: 4px;
                  width: 22px;
                  height: 22px;
                }
              }
            }
          }
          &.active {
            color: $black;
            border-left: 3px solid $xiaomi;
            padding-left: 27px;
          }
          &:hover {
            color: $black;
            cursor: pointer;
          }
          // &:hover {
          //   color: $white;
          // }
        }
      }
      ul.sidebar__sponsors {
        background: transparent;
        box-shadow: none;
        width: 100%;
        li {
          width: 100%;
          max-height: 180px;
          background-color: $white;
          border-radius: 5px;
          box-shadow: 0px 10px 20px #00000033;
          position: relative;
          color: $black;
          display: flex;
          align-items: flex-end;
          flex-wrap: wrap;
          padding: 10px 20px;
          font-weight: 600;
          margin: 10px 0;
          transition: margin 0.3s;
          cursor: pointer;
          span {
            // border-top: 1px solid $white-light;
            padding-top: 5px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            button {
              z-index: 1000;
              background-color: transparent;
              border: none;
              div {
                svg {
                  height: 18px;
                  width: 18px;
                }
              }
            }
            img {
              width: 20px;
              margin: 0;
            }
          }
          &:first-child {
            margin-top: 0px;
          }
        }
      }
    }
  }
  .sidebar__settings {
    display: flex;
    justify-content: center;
    padding-top: 1em;
    align-items: center;
    border-top: 1px solid $border-grey;

    img {
      width: 40px;
      height: 40px;
      margin-right: 20px;
    }

    button {
      padding: 30px 0;
      margin: 0 30px;
      border: 0;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $white;
      width: 100%;
      font-weight: 600;
      cursor: unset;

      &:focus {
        outline: none;
      }

      div svg {
        margin-right: 20px;
      }
    }
  }
}

.sponsor-active {
  transition: margin 0.3s;
  margin-top: 0px !important;
  background: $xiaomi-light !important;
}

.sidebar__dropdown {
  display: flex;
  gap: 10px;
  background-color: transparent;
  border: none;
  margin: 25px 0;
  small {
    margin: 0 !important;
  }
}

.sidebar__event-buttons {
  display: flex;

  button {
    margin: 10px 10px;
    padding: 0 20px;
    height: 50px;
    flex: 1;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div svg {
      height: 15px;
    }

    &:first-child {
      background: linear-gradient(219deg, #00a1ed, #2968bc, #4c3892, #5a2683);
      border: none;
    }
    &:last-child {
      position: relative;
      background: none;
      border: none;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 5px; /*1*/
        border: 2px solid transparent; /*2*/
        background: linear-gradient(219deg, #00a1ed, #2968bc, #4c3892, #5a2683) border-box; /*3*/
        -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out; /*5'*/
        mask-composite: exclude; /*5*/
      }
    }
  }
}

.sidebar__mobile-bar-wrapper {
  display: none;
}

@media (max-width: $desktop) {
  .sidebar__mobile-bar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;
    background-color: $xiaomi;
    z-index: 1000;

    span {
      color: #fff;
      font-weight: bold;
      margin-left: 10px;
    }

    button {
      margin-right: 10px;
      border: none;
      border-radius: 50%;
      background-color: transparent;
      div svg {
        width: 15px;
        height: 15px;
      }
    }

    &-agenda {
      position: fixed;
      bottom: 50px;
      background-color: $grey-light;
      height: 50px;
      justify-content: flex-end;
    }
  }
}

.agenda-bar-item {
  display: flex;
  span {
    margin-right: 10px;
    color: $xiaomi;
  }
  button {
    background-color: #fff;
  }
}

// @media (max-width: $tabletBig) {
//   .sidebar__mobile-bar-wrapper {
//     background-color: $tesla;
//   }
// }

.sidebar__mobile-bar {
  display: flex !important;
  justify-content: center;
  align-items: center;
  // transform: translate(200px, 100px);
}

.sidebar__list-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #f7f8fc;
  img {
    max-width: 80px;
    width: 100%;
    height: 100%;
  }
  button {
    border: none;
    background-color: inherit;
    div svg {
      width: 20px;
      height: 20px;
    }
  }
}

.sidebar__current-event {
  background-color: $white;
  color: $black;
  border-radius: 5px;
  overflow: hidden;
  min-height: 80px;
  max-height: 160px;
  span {
    margin: 0 10px;
    display: block;
    width: 100%;
    word-wrap: break-word;
  }
}

.sidebar__sponsors-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    background-color: inherit;
    border: none;
  }
  div svg {
    width: 20px;
    height: 20px;
  }
  &-image {
    width: 220px;
    height: 60px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

@media (max-width: $desktop) {
  .sidebar {
    display: none;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}
